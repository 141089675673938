.csv-container {
    .csv-reader-input {
        height: 0;
        width: 0;
    } 

    .notice {
        align-items: center;
        border: 2px #dbe5f0 dashed;
        margin: 0 40px 40px;
        padding: 15px;

    }

    label.px-3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        border-image: initial;
        border-radius: 5px;
        cursor: pointer;
        border-radius: 100px;
        background-color: transparent;

        &:hover {
            background-color: #F6F6F6;
        }
    }

    input[type="file"].csv-input {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 0px;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        white-space: nowrap;
        width: 0px;
        
    }

    .csv-input {
        font-size: 1.6rem;
        height: auto;
        transition: all 0.25s ease 0s;
        position: relative;
        margin: 0;
        display: inline-block;
        height: 44px;
        cursor: pointer;
        outline: 0 !important;
        user-select: none;
        font-size: 1.6rem;
        font-weight: 600;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        border-image: initial;
        border-radius: 5px;
        padding: .6em 1em;
        text-decoration: none;
    }

    .description {
        border-left: 1px solid #d0dbd8;

    }
}