.rtresizable-header-content {
    text-transform: capitalize;
}

.MuiInputBase-input {
    &#react-select-single {
        height: 0px !important
    }
}

.async-select-wrapper {
    width: 100%;
}

.async-select-label {
    margin-top: 24px;
}

.progress-bar {
    position: absolute;
    width: 100%;
    top: -58px;
    left: 0;
}
.align-baseline {
    align-items: baseline;
}

.hidden {
    display: none;
}
.upload-checkin-file {
    margin-top: 11px;
}

.upload-notice {
    align-items: center;
    border: 2px #dbe5f0 dashed;
    padding: 15px;

}
.float-right {
    display: flex;
    justify-content: right;
}
.my-masonry-grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px;
    /* gutter size offset */
    width: auto;
    min-height: 200px;
}

.my-masonry-grid_column {
    padding-left: 30px;
    /* gutter size */
    background-clip: padding-box;
}

.the-content {
    margin-top: 10px;
    padding: 30px 15px;
    min-height: calc(100vh - 123px);
}

.rttr:hover {
    background-color: #C5c6d0 !important;
}

.rttr {
    transition: height 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

/* Style your items */
.my-masonry-grid_column>div {
    /* change div to reference your elements you put in <Masonry> */
    background: transparent;
    margin-bottom: 30px;
}

.MuiPickersToolbar-toolbar {
    background-color: #2EACC1 !important;
}
.MuiPickersDay-daySelected { 
    background-color: #2EACC1 !important;
}

.printable {
    display: none;
    @media print {
        display: block;
    }
}

.rdt input.form-control {
    font-size: 14px;
}

.upload-csv-label {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

    div:nth-child(2) {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.sub-row-table {
    padding: 20px;
    max-width: 100vw-350;
}

.flex {
    display: flex;
}

.toggle-btn {
    display: flex;
    margin: 17px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    font-size: 1.8rem !important;
}