.container {
    .content {
        padding: 20px;
        width: 100%;
    }

    .left-content {
        padding-top: 20px;
    }

    &.notice {
        align-items: center;
        border: 2px #dbe5f0 dashed;
        padding: 19px;

    }


    label.px-3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #2EACC1;
        color: rgb(255, 255, 255);
        border: 1px solid  #2EACC1;
        transition: all 0.25s ease 2s;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        border-image: initial;
        border-radius: 5px;
        cursor: pointer;
        padding: 10px;

        &:hover {
            background-color: #50d4eb;
            border-color: #50d4eb;
        }
    }

    input[type="file"].csv-input {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        white-space: nowrap;
        width: 1px;
        // box-shadow: rgb(0 0 0 / 9%) 0px 2px 4px 1px;
        
    }

    .csv-input {
        width: 100%;
        height: 100%;
        background-color: rgb(121, 76, 255);
        color: rgb(255, 255, 255);
        border: 1px solid rgb(121, 76, 255);
        font-size: 1.6rem;
        height: auto;
        transition: all 0.25s ease 0s;
        position: relative;
        margin: 0;
        display: inline-block;
        height: 44px;
        cursor: pointer;
        outline: 0 !important;
        user-select: none;
        // box-shadow: rgb(0 0 0 / 9%) 0px 2px 4px 1px;
        font-family: Gilroy, "Helvetica Neue", Arial, sans-serif;
        font-size: 1.6rem;
        font-weight: 600;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        border-image: initial;
        border-radius: 5px;
        padding: .6em 1em;
        text-decoration: none;
    }

    .description {
        border-left: 1px solid #d0dbd8;
    }
}

.float-right {
    float: right;
}